
const EditCategory = async(field, id, value) => {

    return await fetch("https://vm-f52159ad.na4u.ru/edit_category", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id,
            fields: {
                [field]: value
            }
        })
    })
        .then(response => response.json())

}
export default EditCategory