import { useDispatch } from "react-redux"
import { DELETE_IMAGE } from "../../../reducers/types"
import EditProduct from "../../../queries/EditProduct"

const ImageControlBlock = ({
    select_image, 
    resetMoveImage,
    resetSelectImage,
    onMoveId,
    prod,
    setOnMoveId
}) => {

    const dispatch = useDispatch()
    
    const moveToggleHundler = () => (select_image.img_id === -1 || (onMoveId.img_id === select_image.img_id))
        ? resetMoveImage()
        : setOnMoveId({ prod_id: prod.id, img_id: select_image.img_id })

    const deleteImageHandler = () => {
        
        let new_imgs = prod.imgs.filter(el => el.id === select_image.img_id).map(el => el.img)

        EditProduct("imgs", prod.id, new_imgs).then(response => {
            if(response){
                const { prod_id, img_id } = select_image
                dispatch({ type: DELETE_IMAGE, payload: { prod_id, img_id } })
                resetMoveImage()
                resetSelectImage()
            }
        })
    }

    return <div className="control-block">
        <div onClick={moveToggleHundler} className="move-img-toggle control-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M480-80 310-250l57-57 73 73v-166h80v165l72-73 58 58L480-80ZM250-310 80-480l169-169 57 57-72 72h166v80H235l73 72-58 58Zm460 0-57-57 73-73H560v-80h165l-73-72 58-58 170 170-170 170ZM440-560v-166l-73 73-57-57 170-170 170 170-57 57-73-73v166h-80Z" /></svg>
        </div>
        <div onClick={deleteImageHandler} className="delete-img-toggle control-item">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
        </div>
    </div>
}

export default ImageControlBlock