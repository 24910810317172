import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetOrders from "../../../queries/GetOrders"
import dayjs from "dayjs"
import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import Loading from "../../fixed-elements/Loading"
import CompleteOrder from "../../../queries/CompleteOrder"
import CheckAdmin from "../../../queries/CheckAdmin"

const OrdersPage = () => {
    const navigate = useNavigate()
    const [orders, setOrders] = useState([])
    const [onBlocked, setOnBlocked] = useState([])
    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const option_select = [
        {id: 1, label: "с 08:00 до 10:00"},
        {id: 2, label: "с 10:00 до 12:00"},
        {id: 3, label: "с 12:00 до 14:00"},
        {id: 4, label: "с 14:00 до 16:00"},
        {id: 5, label: "с 16:00 до 18:00"},
        {id: 6, label: "с 18:00 до 20:00"},
        {id: 7, label: "с 20:00 до 22:00"}
    ]

    const checkBlockedId = (id) => onBlocked.some(c_id => c_id === id)
    

    const completeOrderHandler = async(id) => {
        if(!checkBlockedId(id)){
            setOnBlocked([...onBlocked, id])
            await CompleteOrder(id).then(response => {
                response.result && setOrders([
                    ...orders.map(o => o.id === id ? {
                        ...o, 
                        completed: response.result
                    } : o)
                ])
            
                setOnBlocked([...onBlocked.filter(c_id => c_id !== id)])
            })
        }
    }

    useEffect(() => {        
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user)
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => {
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })

        GetOrders().then(response => {
            setOrders(response.result.data)
        })
    }, [navigate])

    return isUserAdmin ? <div className="orders-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="order-content">
            <h1>Заказы</h1>
            {orders.length
            ? orders.sort((a, b) => b.id - a.id)
            .sort((a, b) => a.completed - b.completed).map(o => <div key={o.id} className={"order-item " + (o.completed ? "close-item" : "")}>
                {o.completed ? <h5 className="close-tag">Выполнен</h5> : ""}
                <div className="heading-item"><h2>Заказ №{o.id}</h2><h4 className={o.delivery ? "s1" : "s2"}>{o.delivery ? "Доставка" : "Самовывоз"}</h4></div>
                <p><span className="key">Заказ создан: </span>{ dayjs(o.date_create).format("DD.MM.YYYY hh:mm")}</p>
                <p><span className="key">Статус заказа: </span>{ o.paid === 1 ? "Оплачен" : "Не оплачен" }</p>
                <p><span className="key">Имя покупателя: </span>{o.name}</p>
                <p><span className="key">Номер покупателя: </span>{o.phone_number}</p>
                <p><span className="key">Дата получения: </span>{o.date_receipt}</p>
                <p><span className="key">Время получения: </span>{option_select.filter(sel => +sel.id === +o.time_receipt)[0].label}</p>
                {o.delivery ? <p><span className="key">Адрес: </span>{o.address_delivery}</p> : ""}
                {o.another_person 
                ? <div className="another-person-block">
                    <h4>Получит другой человек:</h4>
                    <p><span className="key">Имя получателя: </span>{o.name_another_person}</p>
                    <p><span className="key">Номер получателя: </span>{o.phone_number_another_person}</p>
                </div>
                : ""}
                {o.postcard 
                ? <div className="postcard-block">
                    <h4>Заказ с открыткой</h4>
                    <p><span className="key">Текст открытки:</span></p>
                    <p>{o.postcard_text}</p>
                </div>
                : ""}
                <div className="product-list">
                    <h3>Товары:</h3>
                    {o.id_product.map(p => <ListItem key={p.id} className="product-item">
                  <ListItemIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#444" d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"/></svg>
                  </ListItemIcon>
                  <ListItemText
                    primary={`№${p.id} - ${p.name} - ${p.price}₽ - ${p.amount}шт`}
                  />
                </ListItem>)}
                <p><span className="key">Итог:</span> <b>{o.id_product.reduce((acc, cur) => acc + (cur.price * cur.amount) , 0)}₽</b></p>
                </div>

                {o.anonymous ? <h5>Анонимно</h5> : ""}
                {!o.completed ? <div onClick={() => completeOrderHandler(o.id)} className={"order-completed-but " + (checkBlockedId(o.id) ? "loading-but" : "")}>
                    {checkBlockedId(o.id)
                    ? <Loading fill="#fff"/>
                    : "Заказ выполнен"}
                    </div> : ""}
            </div>)
            :<Loading />
            }
        </div>
    </div>
    : <Loading />
}

export default OrdersPage