import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import Checkboxs from "./Checkboxs"
import SelectTime from "./SelectTime"
import { useSelector } from "react-redux"
import AddOrder from "../../queries/AddOrder"
import GetProducts from "../../queries/GetProducts"
import { useNavigate } from "react-router-dom"
import GetPriceFromAddress from "../../queries/GetPriceFromAddress"

const BuildOrder = () => {
  const navigate = useNavigate()

  const user_info = useSelector((s) => s.app.user_info)
  const basket_storage = useSelector((s) => s.app.basket)

  const [priceDelivery, setPriceDelivery] = useState(0)

  // информация о заявке
  const [id_select_deliver, setIdSelectDeliver] = useState(1)

  const [date_of_deliver, setDateOfDeliver] = useState(dayjs())
  const [order_time, setOrderTime] = useState(1)

  const [errors, setErrors] = useState({
    name: true,
    number: true,
    address: true,
    number_another: true,
    name_another: true,
    postcard_text: true,
    limitError: true,
    paymentError: true,
  })

  const [addressValid, setAddressValid] = useState(true)
  const [client_name, setClientName] = useState("")
  const [client_number, setClientNumber] = useState()
  const [client_address, setClientAddress] = useState("")

  const [client_other, setClientOther] = useState(false)
  const [client_number_another, setClientNumberAnother] = useState()
  const [client_name_another, setClientNameAnother] = useState("")
  const [client_postcard, setClientPostcard] = useState(false)
  const [client_postcard_text, setClientPostcardText] = useState("")
  const [client_anonim, setClientAnonim] = useState(false)
  const [client_payment, setClientPayment] = useState(false)
  // конец информации о заявке

  const paymentHandler = async () => {
    
    const new_errors = {
      name: client_name ? true : false,
      number: client_number ? true : false,
      address: (client_address && id_select_deliver === 2) || id_select_deliver !== 2 ? true : false,
      number_another: (client_number_another && client_other) || !client_other ? true : false,
      name_another: (client_name_another && client_other) || !client_other ? true : false,
      postcard_text: (client_postcard_text && client_postcard) || !client_postcard ? true : false,
      priceDelivery: (id_select_deliver === 2 && !priceDelivery) ? false : true,
      limitError: true,
      paymentError: true,
    }
    setErrors(new_errors)

    if ((!Object.keys(new_errors).some((er) => new_errors[er] === false))) {
      if((id_select_deliver === 2 && addressValid) || id_select_deliver === 1) sendInfo()
    }
  }

  const sendInfo = async () => {
      let local_data_basket = []
      let local_amount = 0

      basket_storage.length &&
        (await GetProducts(
          null,
          10000,
          null,
          basket_storage.map((el) => el.id)
        ).then((response) => {
          local_amount = response.result.data.reduce((acc, p) => (acc += p.price * basket_storage.filter((b) => b.id === p.id)[0].amount), 0)
          response.result.data.forEach((p) => {
            local_data_basket.push({
              id: p.id,
              name: p.name,
              price: p.price,
              img: p.imgs[0],
              amount: basket_storage.filter((b) => b.id === p.id)[0].amount,
            })
          })
        }))

      let products_order = []

      local_data_basket.forEach((el) => {
        products_order.push({
          id: el.id,
          name: el.name,
          price: el.price,
          amount: +basket_storage.filter((b) => b.id === el.id)[0].amount,
        })
      })
      
      
      if (id_select_deliver === 1 && client_payment && local_amount > 40000) setErrors({ ...errors, limitError: false })
      else{
        AddOrder(products_order, date_of_deliver.format("DD/MM/YYYY"), order_time, client_name, client_number, client_address, client_anonim, client_postcard, client_postcard_text, client_other, client_name_another, client_number_another, id_select_deliver, 0)
        .then((response) => {
          if (response.result.id) {
            if ((id_select_deliver === 1 && !client_payment) || id_select_deliver === 2) {
              payOrderHandler(response.result.id)
            } else {
              navigate("/thank")
            }
          }
        })
      }
  }

  async function payOrderHandler (id_order) {
    let tg = window.Telegram.WebApp;
    basket_storage.length &&
      (await GetProducts(
        null,
        10000,
        null,
        basket_storage.map((el) => el.id)
      ).then(async (response) => {
        const amount = +response.result.data.reduce((acc, p) => (acc += p.price * basket_storage.filter((b) => b.id === p.id)[0].amount), 0) + (+priceDelivery)
        
        await fetch("https://vm-f52159ad.na4u.ru/pay_order", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              amount,
              id_user: tg.initDataUnsafe.user.id,
              id_order
            }),
          }).then((response) => response.json())
          .then((response) => {
            console.log(response)
            if(response.result) {
              localStorage.setItem("payment-id-dff-store", response.result.payment_id)
              localStorage.setItem("payment-url-dff-store", response.result.payment_url)
              localStorage.setItem("payment-first-dff-store", true)
              navigate("/thank")
            }
            else setErrors({ ...errors, paymentError: false })
          })
    }))
  }


  useEffect(() => {

    setClientAddress(user_info.address || "")
    setClientName(user_info.name || "")
    setClientNumber(user_info.phone_number || "")

  }, [user_info.address, user_info.name, user_info.phone_number])


  useEffect(() => {
    let timer = setTimeout(() => {
    (id_select_deliver === 2 && client_address) &&
    GetPriceFromAddress(client_address).then(function(response) {
      setPriceDelivery(null)
      if(response.result && response.result.price) {
        setPriceDelivery(response.result.price)
        setAddressValid(true)
      }
      else setAddressValid(false)
    })
  }, 1000)

  return () => clearTimeout(timer)
  }, [client_address, id_select_deliver])


  return (
    <div className="build-order-container">
      <div className="deliver-block">
        <div className={"select-item " + (id_select_deliver === 1 ? "active" : "")} onClick={() => setIdSelectDeliver(1)}>
          <p>Самовывоз</p>
        </div>
        <div className={"select-item " + (id_select_deliver === 2 ? "active" : "")} onClick={() => setIdSelectDeliver(2)}>
          <p>Доставка</p>
        </div>
      </div>
      <div className="order-info-block">
        <p className="heading long">Дата и время {id_select_deliver === 1 ? "самовывоза" : "доставки и адрес"}</p>
        <DatePicker className="date-order" onChange={(e) => setDateOfDeliver(e)} value={date_of_deliver} />

        <SelectTime order_time={order_time} setOrderTime={setOrderTime} />

        {id_select_deliver === 2 && <TextField className="address-order input-type" label="Адрес доставки" onChange={(e) => setClientAddress(e.target.value)} value={client_address} fullWidth />}
        {(id_select_deliver === 2 && !errors.address) ? <p className="error">Заполните адрес</p> : ""}
        {(id_select_deliver === 2 && !addressValid) && <p style={{color: "#cccc00", textAlign: "left"}}>Ошибка: адрес доставки не найден, либо вы живете не в Санкт-Петербурге.</p>}

        <p className="heading long">Контактные данные</p>
        <TextField className="name-order input-type" label="Имя покупателя" onChange={(e) => setClientName(e.target.value)} value={client_name} fullWidth />
        {!errors.name ? <p className="error">Заполните имя</p> : ""}
        <PhoneInput value={client_number} onChange={(e) => setClientNumber(e)} containerClass="number-order input-type" country="ru" />
        {!errors.number ? <p className="error">Заполните номер</p> : ""}
        <Checkboxs client_other={client_other} setClientOther={setClientOther} client_postcard={client_postcard} setClientPostcard={setClientPostcard} client_anonim={client_anonim} setClientAnonim={setClientAnonim} client_payment={client_payment} setClientPayment={setClientPayment} client_number_another={client_number_another} setClientNumberAnother={setClientNumberAnother} client_name_another={client_name_another} setClientNameAnother={setClientNameAnother} client_postcard_text={client_postcard_text} setClientPostcardText={setClientPostcardText} errors={errors} id_select_deliver={id_select_deliver} />
      </div>
      {!errors.paymentError && <p style={{color: "red", textAlign: "center"}}>Ошибка: вы находитесь вне бота.</p>}
      {!errors.limitError && <p style={{color: "red", textAlign: "center"}}>Ошибка: при заказе с оплатой при получении, заказ должен быть на сумму не более 40'000 рублей</p>}
      
      {id_select_deliver === 2 && 
        (client_address 
        ? priceDelivery && <p style={{color: "#cccc00", textAlign: "center"}}>Стоимость доствки будет составлять: {priceDelivery} рублей</p>
        :<p style={{color: "#cccc00", textAlign: "center"}}>Введите адрес, чтобы узнать стоимость доставки</p>)
        }
      <div className="build-order-but" onClick={paymentHandler}>
        Оформить заказ
      </div>
    </div>
  )
}

export default BuildOrder
