const GetAllProducts = async(limit, offset) => {

    return await fetch("https://vm-f52159ad.na4u.ru/get_products", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({limit, offset})
    })
        .then(response => response.json())
}

export default GetAllProducts