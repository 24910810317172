const UploadImage = async (files) => {

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
    }

    return await fetch("https://vm-f52159ad.na4u.ru/upload", {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
}

export default UploadImage