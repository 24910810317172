const DeleteProduct = async(id) => {

    return await fetch("https://vm-f52159ad.na4u.ru/delete_product", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id})
    })
        .then(response => response.json())

}
export default DeleteProduct