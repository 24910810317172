const NewProduct = async (name, price, imgs, ctgs) => {

    return await fetch("https://vm-f52159ad.na4u.ru/add_product", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({fields: {name, price, imgs, ctgs}})
    })
        .then(response => response.json())
}
export default NewProduct