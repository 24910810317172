import { TextField } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { CHANGE_CLIENT_ADDRESS, CHANGE_CLIENT_NAME, CHANGE_CLIENT_NUMBER } from "../../reducers/types"
import { NavLink } from "react-router-dom"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EditProfile from "../../queries/EditProfile"
import { useEffect, useState } from "react"
import CheckAdmin from "../../queries/CheckAdmin"

const UserInfo = () => {

    const user_info = useSelector(s => s.app.user_info)
    const dispatch = useDispatch()
    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const [localName, setLocalName] = useState(user_info.name)
    const [localNumber, setLocalNumber] = useState(user_info.phone_number)
    const [localAddress, setLocalAddress] = useState(user_info.address)
    const [onSave, setOnSave] = useState(false)
    const [errorFromServer, setErrorFromServer] = useState(false)

    const editProfileHandler = () => {
      setErrorFromServer(false)
      EditProfile(localName, localNumber, localAddress, user_info.id).then((resp) => {
        setOnSave(resp.result)
        if(resp.result){
          dispatch({ type: CHANGE_CLIENT_NAME, payload: localName })
          dispatch({ type: CHANGE_CLIENT_NUMBER, payload: localNumber })
          dispatch({ type: CHANGE_CLIENT_ADDRESS, payload: localAddress })
          let timer = setTimeout(() => {
            setOnSave(false)
            clearTimeout(timer)
          }, 1000)
        }else{
          setErrorFromServer(resp.erorr)
        }
      })
    }


    useEffect(() => {
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) {
            CheckAdmin(tg.initDataUnsafe.user.id).then(response => {
                setIsUserAdmin(response.result)
            })
        }

    }, [])

    return <div className="user-info-container">
        <div className="heading-block">
            <h1>Ваш аккаунт</h1>
            {isUserAdmin && <NavLink className="admin-panel-button" to="/adm7730s">Админ панель</NavLink>}
        </div>
        <TextField onChange={e => setLocalName(e.target.value)} label="Имя" value={localName} className="name" />
        <PhoneInput
            value={localNumber}
            onChange={e => setLocalNumber(e)}
            containerClass="phone-number"
            country='ru' />
        <TextField onChange={(e) => setLocalAddress(e.target.value)} type="text" id="street-address" name="street-address" autoComplete="street-address" label="Адрес" value={localAddress} className="address" />
        <p className="prompt">Например, Санкт-Петербург, ул. Короленко, д.8</p>
        {errorFromServer && <p className="error" style={{ textAlign: "center", margin: "0" }}>{errorFromServer}</p>}
        {onSave
            ? <div className="save_message">Изменения сохранены!</div>
            : <div onClick={editProfileHandler} className="save-but">Сохранить изменения</div>}
    </div>
}

export default UserInfo