const AddOrder = async(id_product, date_receipt, time_receipt, name, phone_number, address_delivery, anonymous, postcard, postcard_text, another_person, name_another_person, phone_number_another_person, delivery, completed) => {

    return await fetch("https://vm-f52159ad.na4u.ru/add_order", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({fields: {id_product, date_receipt, time_receipt, name, phone_number, address_delivery, anonymous, postcard, postcard_text, another_person, name_another_person, phone_number_another_person, delivery, completed}})
    })
        .then(response => response.json())

}
export default AddOrder