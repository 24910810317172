
const EditContacts = async(adress, contact_phone, telegram) => {

    return await fetch("https://vm-f52159ad.na4u.ru/edit_contacts", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            fields: {adress, contact_phone, telegram}
        })
    })
        .then(response => response.json())

}
export default EditContacts