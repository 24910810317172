const NewCategory = async (name) => {

    return await fetch("https://vm-f52159ad.na4u.ru/add_category", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({name})
    })
        .then(response => response.json())
}
export default NewCategory