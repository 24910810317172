import './style.scss';
import { HashRouter, Route, Routes } from 'react-router-dom'
import Home from './components/home/Home.js';
import ActiveMenu from './components/fixed-elements/ActiveMenu.js';
import Header from './components/fixed-elements/Header.js';
import { useEffect } from 'react';
import ProductPage from './components/product-page/ProductPage.js';
import BasketPage from './components/basket-page/BasketPage.js';
import { useDispatch } from 'react-redux';
import { CHANGE_CLIENT_ADDRESS, CHANGE_CLIENT_ID, CHANGE_CLIENT_NAME, CHANGE_CLIENT_NUMBER, LOAD_BASKET, SET_CATEGORIES } from './reducers/types.js';
import BuildOrder from './components/build-order/BuildOrder.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Contacts from './components/contacts/Contacts.js';
import CategoryPage from './components/category-page/CategoryPage.js';
import AdminPanel from './components/admin-panel/AdminPanel.js';
import UserInfo from './components/user-info/UserInfo.js';
import CategoriesPage from './components/admin-panel/categories-page/CategoriesPage.js';
import ContactsPage from './components/admin-panel/contacts-page/ContactsPage.js';
import ProductsPage from './components/admin-panel/products-page/ProductsPage.js';
import OrdersPage from './components/admin-panel/orders-page/OrdersPage.js';
import AddProductPage from './components/admin-panel/add-product-page/AddProductPage.js';
import GetCategories from './queries/GetCategories.js';
import ThankPage from './components/thank-page/ThankPage.js';
import BlockPCPage from './components/block-pc/BlockPCPage.js';
import GetProfile from './queries/GetProfile.js';

function App() {
  const dispatch = useDispatch();

  const Redirect = () => {
    return window.location.assign('http://localhost:3000')
  }
  useEffect(() => {
    let tg = window.Telegram.WebApp;
    const storage_basket = localStorage.getItem("basket-dff-store")
    storage_basket && dispatch({type: LOAD_BASKET, payload: JSON.parse(storage_basket)})
    GetCategories().then(data => {dispatch({type: SET_CATEGORIES, payload: data.result.data})})


    if (tg.initDataUnsafe.user) {
      GetProfile(tg.initDataUnsafe.user.id).then((response) => {
        dispatch({ type: CHANGE_CLIENT_ID, payload: response.result.id })
        response.result.data.name && dispatch({ type: CHANGE_CLIENT_NAME, payload: response.result.data.name })
        response.result.data.phone_number && dispatch({ type: CHANGE_CLIENT_NUMBER, payload: response.result.data.phone_number })
        response.result.data.address && dispatch({ type: CHANGE_CLIENT_ADDRESS, payload: response.result.data.address })

      })
    }

  }, [dispatch])

  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HashRouter >
      {isMobile()
      ? <div className="global-container">
        <ActiveMenu/>
        <Header/>
        <Routes>
          <Route path="*" element={<p>Такой страницы нет</p>} />
          <Route path="/" element={<Home />} />
          <Route path="/p/:id" element={<ProductPage />} />
          <Route path="/p" element={<Redirect />} />
          <Route path="/basket" element={<BasketPage />} />
          <Route path="/thank" element={<ThankPage />} />
          <Route path="/order" element={<BuildOrder />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/ctg/:id" element={<CategoryPage />} />
          <Route path="/adm7730s" element={<AdminPanel />} />
          <Route path="/user_info" element={<UserInfo />} />
          <Route path="/adm7730s/ctgs" element={<CategoriesPage />} />
          <Route path="/adm7730s/contacts" element={<ContactsPage />} />
          <Route path="/adm7730s/orders" element={<OrdersPage />} />
          <Route path="/adm7730s/products" element={<ProductsPage />} />
          <Route path="/adm7730s/products/new" element={<AddProductPage />} />
        </Routes>

      </div>
      : <BlockPCPage/>}
    </HashRouter>
    </LocalizationProvider>
  );
}

export default App;
