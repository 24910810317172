const GetProducts = async(ctgs, limit, offset, ids) => {

    return await fetch("https://vm-f52159ad.na4u.ru/get_products", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ctgs, limit, offset, ids})
    })
        .then(response => response.json())
}

export default GetProducts