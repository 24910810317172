const EditProfile = async(localName, localNumber, localAddress, id) => {
    
    return await fetch("https://vm-f52159ad.na4u.ru/edit_profile", {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({id, fields: {name: localName, phone_number: localNumber, address: localAddress}})
    })
        .then(response => response.json())

}
export default EditProfile