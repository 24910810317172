const GetOrders = async() => {

    return await fetch("https://vm-f52159ad.na4u.ru/get_orders", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({limit: 100000})
    })
        .then(response => response.json())
}

export default GetOrders